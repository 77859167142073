import React from 'react'

import { ArrowLeft } from '@kushki/suka-react-system-icons';
import Link from '../Link/Link';

import notFoundImage from './not-found.svg'

import './styles.scss'
import { FormattedMessage } from 'react-intl';

const NotFound: React.FC = () => {

  return (
    <div className="not-found-container">
      <img src={notFoundImage} alt="not found" className="not-found-image"/>
      <h1 className="mb-layout-2">
      <FormattedMessage id="page_404.title" /></h1>
      <p className="not-found-message">
        <FormattedMessage id="page_404.description" />
      </p>
      <a href="javascript:history.back()" className="btn--basic btn--hover" >
        <ArrowLeft size={16} /> <FormattedMessage id="page_404.button_back" /></a>
      <Link to="/" className="btn--basic btn--hover ml-2" >
      <FormattedMessage id="page_404.button_init" /></Link>
    </div>
  )
}

export default NotFound;
