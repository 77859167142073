import React from 'react'
import NotFound from '../components/404/404'
import browserLang from 'browser-lang';

import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import locales, { LocaleTypes } from '../data/locales'

interface NotFoundTemplateProps {
  location: Location
  pathContext: {
    localeCode: LocaleTypes
  }
}



const NotFoundPage: React.FC<NotFoundTemplateProps> = ({pathContext, location}) => {
  // let locale = pathContext.localeCode
  const getPreferredLanguage: any = () => {
    let preferredLanguage = typeof window !== 'undefined' && window.localStorage.getItem('preferredLanguage');
  
    if(preferredLanguage === null) {
      preferredLanguage = `${browserLang({
        fallback: 'es',
        languages: locales.map(locale => locale.code),
      })}`;
  
      localStorage.setItem('preferredLanguage', preferredLanguage);
    }

    return preferredLanguage;
  }
  const preferredLanguage = getPreferredLanguage()

  // console.log('pokemon', pathContext)

  return (
    <Layout location={location} locale={preferredLanguage} >
      <SEO title="404: Not found" />
      <NotFound/>
    </Layout>
  )}

export default NotFoundPage
